import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SatSatNovaComponent } from './sat/sat-nova/sat-nova.component';
import { DuvidasComponent } from './sat/duvidas/duvidas.component';
import { SatSatInstrucoesComponent } from './sat/sat-instrucoes/sat-instrucoes.component';
import { SatSatListaComponent } from './sat/sat-lista/sat-lista.component';
import { SatDetalhesComponent } from './sat/sat-detalhes/sat-detalhes.component';
import { LoginComponent } from './sessions/login/login.component';
import { Error403Component } from './sessions/403.component';
import { Error404Component } from './sessions/404.component';
import { Error500Component } from './sessions/500.component';
import { AuthGuard } from '@core';
import { CatalogoServicosComponent } from './sat/catalogo-servicos/catalogo-servicos.component';
import { SatEditarComponent } from './sat/sat-editar/sat-editar.component';
import { TemasCriticosComponent } from './sat/temas-criticos/temas-criticos.component';
import { CriteriosPrioridadesComponent } from './sat/criterios-prioridades/criterios-prioridades.component';
import { ServicosTecnicosComponent } from './admin/servicos-tecnicos/servicos-tecnicos.component';
import { ListaServicosComponent } from './admin/lista-servicos/lista-servicos.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [

      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'sat-nova', component: SatSatNovaComponent },
      { path: 'sat-lista', component: SatSatListaComponent },
      { path: 'catalogo-servicos', component: CatalogoServicosComponent },
      { path: 'sat-detalhes', component: SatDetalhesComponent },
      { path: 'sat-instrucoes', component: SatSatInstrucoesComponent },
      { path: 'sat-duvidas', component: DuvidasComponent },
      { path: 'sat-editar', component: SatEditarComponent },
      { path: 'criterios-prioridade', component: CriteriosPrioridadesComponent },
      { path: 'temas-criticos', component: TemasCriticosComponent },
      { path: '403', component: Error403Component },
      { path: '404', component: Error404Component },
      { path: '500', component: Error500Component },
      //  { path: 'admin', component: ServicosTecnicosComponent },
      { path: 'admin', component: ListaServicosComponent },
      { path: 'sat', loadChildren: () => import('./sat/sat.module').then(m => m.SatModule) },
      { path: '', redirectTo: 'sat-lista', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [{ path: 'login', component: LoginComponent }],
  },
  { path: '**', redirectTo: 'sat-lista' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule { }
