import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SatFormService } from '../../../../services/sat-form.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-procedimento',
  templateUrl: './procedimento.component.html',
  styleUrls: ['../../sat-nova.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcedimentoComponent implements OnInit {
  procedimentosArr: any = [];
  procedimentosFiltradosArr: any = [];
  showSelectProced = false;
  procTemp = null;
  showError = false
  municipiosArr: any[] = []; // Replace with your actual municipios data
  filteredMunicipios: any = [];
  showSelectMun = false;
  searchMunicipio = '';
  disableSelect = false

  itsComplemArr: any = [];
  itsComplemFiltradosArr: any = [];
  searchITNumero: string = '';
  timeout: any = null;

  spinnerMunicipios = false;
  spinnerProcedimentos = false;
  spinnerItsComplem = false;
  spinnerSearchIT = false

  showNenhumDocumento = false

  procNROriginal = null
  sistemaOrigemArrTemp: any = []
  sistemaOrigemArr = [
    {
      "pssoDk": 1,
      "pssoNmSistema": "MGP",
      "formato": "000000000000",
      "isIntegra": false

    },
    {
      "pssoDk": 2,
      "pssoNmSistema": "SEI",
      "formato": "00.00.0000.0000000.0000-00",
      "isIntegra": false
    },
    {
      "pssoDk": 3,
      "pssoNmSistema": "INTEGRA EXTRAJUDICIAL",
      "formato": "000000000000000000000",
      "isIntegra": true
    },
    {
      "pssoDk": 4,
      "pssoNmSistema": "INTEGRA JUDICIAL",
      "formato": "0000000-00.0000.0.00.0000",
      "isIntegra": true
    },
    {
      "pssoDk": 5,
      "pssoNmSistema": "INTEGRA POLICIAL",
      "formato": "000000000000",
      "isIntegra": true
    }
  ]



  constructor(public satFormService: SatFormService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

    console.log(this.satFormService.isEdicao)
    console.log(this.satFormService.isIntegra)

    window.scrollTo({ top: 0, behavior: 'smooth' });






    /* if (this.satFormService.isEdicao === true) {
      if (this.satFormService.isIntegra === true) {
        for (const origem of this.sistemaOrigemCompleto) {
          if (origem.isIntegra === true) {
            this.sistemaOrigemArr.push(origem)
          }
        }
      } else {
        for (const origem of this.sistemaOrigemCompleto) {
          if (origem.isIntegra === false) {
            this.sistemaOrigemArr.push(origem)
          }
        }
      }

    } else {
      this.sistemaOrigemArr = this.sistemaOrigemCompleto
    } */



    // if (this.satFormService.preForm.procedimentoObj === null) {
    this.getItsByOrgao()
    // }


    this.satFormService.itsComplemArr = this.satFormService.itsComplemArr;
    if (this.satFormService.procedimentosUsuSolicit.length === 0) {
      //  this.spinnerProcedimentos = true;
    }

    this.satFormService.procedimentosLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        this.procedimentosArr = this.satFormService.procedimentosUsuSolicit;
        setTimeout(() => {
          this.spinnerProcedimentos = false;
          this.cd.detectChanges();
        }, 1000);
      }
    });







    let insertEstadoRJ = false
    if (this.satFormService.municipiosArr.length === 0) {
      this.spinnerMunicipios = true;
      insertEstadoRJ = true
    } else {
      insertEstadoRJ = false
      if (this.satFormService.municipiosArr[0].selected === true) {
        this.deselectMun(this.satFormService.municipiosArr[0])
      }
    }



    this.satFormService.municipiosLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        this.municipiosArr = this.satFormService.municipiosArr;
        setTimeout(() => {
          this.filteredMunicipios = this.municipiosArr
          this.checkSelectedMunicipios()
          this.showSelectMun = true;

          this.spinnerMunicipios = false;
          this.cd.detectChanges();
        }, 100);
      }
    });





  }

  origemChange(event: any) {
    // let current = this.satFormService.procNR
    //this.satFormService.procNR = ''

    setTimeout(() => {
      this.satFormService.preForm.procedimentoObj = null
      this.showError = false
      //    this.satFormService.procNR = current
      this.cd.detectChanges();
    }, 600);



    this.satFormService.checkPreFormToNavigate()

  }


  getProcNR(nunDocAndOrigem: any) {
    this.showError = false
    this.spinnerProcedimentos = true;
    this.satFormService.getProcNR(nunDocAndOrigem)
    this.satFormService.procedimentoNRLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        this.satFormService.checkPreFormToNavigate()
        this.showError = true
        setTimeout(() => {
          this.spinnerProcedimentos = false;
          this.cd.detectChanges();
        }, 100);
      }
    });
  }



  deleteProcedimento() {
    this.showError = false
    // this.satFormService.procNR = ''
    this.procTemp = this.satFormService.preForm.procedimentoObj
    this.satFormService.preForm.procedimentoObj = null

  }

  filterMunicipios(value: string): void {
    const filterValue = this.removeDiacritics(value.toLowerCase());
    this.filteredMunicipios = this.municipiosArr.filter((munic: any) =>
      this.removeDiacritics(munic.CIDA_NM_CIDADE.toLowerCase()).includes(filterValue)
    );
  }

  removeDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  selectMunicipio(municipio: any) {

    this.filteredMunicipios.forEach((municipioObj: any) => {
      if (municipioObj.selected === true && municipio.CIDA_DK === municipioObj.CIDA_DK) {
        this.satFormService.preForm.municipiosArr.push(municipio);
      }
      if (municipioObj.selected === false && municipio.CIDA_DK === municipioObj.CIDA_DK) {
        this.satFormService.preForm.municipiosArr = this.satFormService.preForm.municipiosArr.filter(
          (municipio: any) => municipio.CIDA_DK !== municipioObj.CIDA_DK
        );
      }
    });

    this.deselectMun(municipio)

    if (this.satFormService.preForm.municipiosArr.length > 0) {
      this.satFormService.preForm.municipioObj = this.satFormService.preForm.municipiosArr[0]
    } else {
      this.satFormService.preForm.municipioObj = null
    }



    this.satFormService.checkPreFormToNavigate()

  }

  deselectMun(municipio: any) {
    if (municipio.CIDA_DK === 0) {
      if (municipio.selected === true) {
        this.disableSelect = true
        this.satFormService.preForm.municipiosArr = []
        this.satFormService.preForm.municipiosArr.push(municipio)
        this.filteredMunicipios.forEach((municipioObj: any) => {
          if (municipioObj.CIDA_DK !== 0) {
            municipioObj.selected = false
          }
        })
      } else {
        this.disableSelect = false
      }
    } else {
      if (municipio.selected === true) {
        this.disableSelect = false
        this.filteredMunicipios[0].selected = false
      }
    }

  }

  checkSelectedMunicipios() {

    if (this.satFormService.preForm.municipiosArr.length > 0) {


      for (const munFromArray of this.satFormService.preForm.municipiosArr) {
        this.filteredMunicipios.forEach((municipioObj: any) => {
          if (munFromArray.CIDA_DK === municipioObj.CIDA_DK) {
            municipioObj.selected = true
          } else {
            //  municipioObj.selected = false
          }
        });
      }


    } else {

    }
  }

  getItsByOrgao() {

    if (this.satFormService.preForm.complementacaoObj === null) {
      this.satFormService.preForm.complementacaoObj.isComplementacao = "N"
      this.satFormService.itsComplemArr = []
    }
    // this.satFormService.getItsByOrgi_Dk()
    // if(this.satFormService.itsComplemArr.length === 0){
    this.spinnerItsComplem = true;
    //  }

    if (this.itsComplemArr.length === 0) {

      this.showNenhumDocumento = true
    } else {
      this.showNenhumDocumento = false
    }



    this.satFormService.itsComplemLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        // this.satFormService.itsComplemArr = this.satFormService.itsComplemArr;

        this.itsComplemArr = this.satFormService.itsComplemArr


        // this.itsComplemFiltradosArr = this.satFormService.itsComplemArr

        setTimeout(() => {
          this.spinnerItsComplem = false;
          if (this.itsComplemArr.length === 0) {
            //  this.satFormService.preForm.complementacaoObj.isComplementacao = "N"

          }
          this.cd.detectChanges();


        }, 200);
      }
    });

  }

  filterITs(event: any) {
    this.spinnerSearchIT = true
    clearTimeout(this.timeout);
    const filterValue = this.searchITNumero.toLowerCase().trim();
    let _self = this
    this.timeout = setTimeout(function () {

      if (filterValue === '') {
        _self.itsComplemFiltradosArr = []; // Reset to all items
      } else {
        _self.itsComplemFiltradosArr = _self.itsComplemArr.filter((it: any) =>
          it.num_IT.toLowerCase().includes(filterValue) ||
          it.mprj.toLowerCase().includes(filterValue)
        );
      }
      setTimeout(() => {

        _self.spinnerSearchIT = false
        _self.cd.detectChanges();


      }, 200);

    }, 400);
    // console.log(filterValue, this.itsComplemFiltradosArr);
  }
}




