<div *ngIf="editVinculo === true && vinculoTipo==='temas'"
     style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px;">
  <!-- First button aligned to the start (left) -->
  <button mat-stroked-button color="accent" (click)="cancelDialog()">
    <mat-icon>arrow_back</mat-icon>
    Voltar
  </button>
  <h1 mat-dialog-title>Editar vínculos</h1>
  <!-- Second button aligned to the end (right) -->
  <button mat-flat-button color="accent"
          style="margin-right: 20px;"
          (click)="saveEditVinculoTemaServ()">Salvar vínculo com Tema</button>

</div>
<div *ngIf="editVinculo === true && vinculoTipo==='documentos'"
     style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px;">

  <!-- First button aligned to the start (left) -->
  <button mat-stroked-button color="accent" (click)="vinculoTipo='temas'">
    <mat-icon>arrow_back</mat-icon>
    Voltar
  </button>
  <h1 mat-dialog-title>Editar vínculos</h1>
  <!-- Second button aligned to the end (right) -->
  <button mat-flat-button color="accent"
          style="margin-right: 20px;"
          (click)="saveEditVinculoServDoc()">Salvar vínculos com Documentos</button>

</div>
<div *ngIf="editVinculo === false"
     style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px;">
  <!-- First button aligned to the start (left) -->
  <button mat-stroked-button color="accent" (click)="cancelDialog()">
    <mat-icon>arrow_back</mat-icon>
    Voltar
  </button>
  <h1 mat-dialog-title>Editar serviço técnico</h1>
  <!-- Second button aligned to the end (right) -->
  <button [disabled]="editingServicoTecnico.pstcDsServTec === '' ||  editingServicoTecnico.pstcNmServTec === '' || editingServicoTecnico.pstcDsServTec === null ||  editingServicoTecnico.pstcNmServTec === null || nomeDuplicado === true"
          mat-flat-button color="accent"
          style="margin-right: 20px;"
          (click)="saveEditServicoTecnico('UPDATE')">Salvar alterações</button>

</div>

<div mat-dialog-content *ngIf="editVinculo === false">
  <!--     EDITAR/CRIAR SERVICO TECNICO   -----------------------------------------------------------------    -->


  <div class="borda">


    <div style="margin-bottom: 30px; margin-top: 20px;">
      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label>Nome do Serviço Técnico</mat-label>

        <textarea rows="3" matInput (ngModelChange)="compareObjectsByName()"
                  [(ngModel)]="editingServicoTecnico.pstcNmServTec">       </textarea>
      </mat-form-field>
    </div>
    <div style="margin-bottom: 30px;">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Descrição do Serviço Técnico</mat-label>
        <textarea matInput (ngModelChange)="compareObjectsByName()"
                  [(ngModel)]="editingServicoTecnico.pstcDsServTec"
                  rows="5"></textarea>
      </mat-form-field>
    </div>


  </div>


  <!-- Add other fields as needed, e.g., description, status, etc. -->
</div>




<div mat-dialog-content *ngIf="editVinculo === true">
  <!--     EDITAR/CRIAR VINCULOS   -----------------------------------------------------------------    -->

  <div *ngIf=" vinculoTipo === 'temas'">

    <p>
      Selecione a quais <strong> Temas </strong> deseja vincular ao Serviço Técnico:
      <strong>{{editingServicoTecnico.pstcNmServTec}}</strong>
    </p>


    <!-- Search input -->
    <mat-form-field appearance="outline" style="width: calc(100% - 7px);">
      <mat-icon matPrefix>search</mat-icon>
      <input type="text"
             placeholder="Busque pelo tema"
             matInput
             [(ngModel)]="searchTermTema"
             (input)="filterTemas()" />
      <!-- Use (input) instead of (keyup) for immediate changes -->
      <button color="accent"
              *ngIf="searchTermTema"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSearch($event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Chip list for filtering themes -->
    <mat-chip-list aria-label="Theme filtering" style="margin-bottom: 10px;">
      <mat-chip
                selectable="false"
                [selected]="selectedFilter === 'todos'"
                (click)="selectedFilter = 'todos';filterTemas()"
                color="accent">
        Todos
      </mat-chip>
      <mat-chip
                selectable="false"
                [selected]="selectedFilter === 'vinculados'"
                (click)="selectedFilter = 'vinculados';filterTemas()"
                color="accent">
        Vinculados
      </mat-chip>
      <mat-chip
                selectable="false"
                [selected]="selectedFilter === 'nao-vinculados'"
                (click)="selectedFilter = 'nao-vinculados';filterTemas()"
                color="accent">
        Não vinculados
      </mat-chip>
    </mat-chip-list>
    <div class="borda" style="height: 380px;    margin-top: 10px;
    ">





      <!-- Render the filtered temasArr based on chip selection -->
      <div class="temas-container list-view"
           *ngIf="filteredTemasArr.length > 0 && spinnerVinculos === false">
        <ul style="list-style-type: none; padding-left: 0;">
          <li *ngFor="let tema of filteredTemasArr"
              style="border-bottom: 1px dotted #c9c9c9; padding-bottom: 5px; margin-bottom: 5px;">
            <div class="temas"
                 style="display: flex; align-items: center; justify-content: space-between;">

              <!-- Left section: Delete button or Checkbox -->
              <div style="display: flex; align-items: center;">
                <!-- Delete button aligned like a checkbox, 5px up, 3px to the left -->
                <button *ngIf="tema.disabled" mat-icon-button color="warn"
                        (click)="deleteVinculoTemaServ(tema.tmanDk)"
                        style=" margin-top: -13px; margin-left: -10px;height: 30px;">
                  <mat-icon>delete</mat-icon>
                </button>

                <!-- Checkbox for enabled themes -->
                <mat-checkbox [(ngModel)]="tema.checked" *ngIf="!tema.disabled"
                              (ngModelChange)="toggleTemaCheck(tema)"
                              style="margin-right: 10px;">
                  {{ tema.tema | lowercaseAndTitlecase }}
                </mat-checkbox>

                <!-- Disabled themes text (aligned with delete button) -->
                <div *ngIf="tema.disabled"
                     style="flex-grow: 1; display: flex; align-items: center;">
                  <span>{{ tema.tema | lowercaseAndTitlecase }}</span>
                </div>
              </div>

              <!-- Right section: 'Documentos' button at the right end -->
              <button *ngIf="tema.disabled" mat-stroked-button color="accent"
                      (click)="selectTemaServTec(tema, editingServicoTecnico)"
                      style=" line-height: 26px !important; font-size: 14px;">
                Documentos
              </button>
            </div>
          </li>
        </ul>
      </div>






      <!-- Show message if there are no temas -->
      <div *ngIf="temasArr.length === 0">
        <p>Nenhum tema disponível.</p>
      </div>


    </div>
  </div> <!-- FIM if vinculo tipo =  temas -->





  <!-- LISTA DE DOCUMENTOS COM CHECKBOXES ---------------------------------------------- -->

  <div *ngIf="vinculoTipo==='documentos'">


    <p>
      Selecione a quais <strong> Documentos </strong> deseja vincular o Serviço Técnico:
      <strong>{{editingServicoTecnico.pstcNmServTec}}</strong> do Tema
      <strong>{{editingServicoTecnico.tema.tema}}</strong>
    </p>









    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Buscar</mat-label>
      <input matInput [(ngModel)]="searchTermDoc" (input)="filterDocuments()"
             placeholder="Buscar por nome do documento">
      <button color="accent"
              *ngIf="searchTermDoc"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSearchDoc($event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-chip-list aria-label="Theme filtering" style="margin-bottom: 10px;">
      <mat-chip
                selectable="false"
                [selected]="selectedFilterDocs === 'todos'"
                (click)="selectedFilterDocs = 'todos';filterDocuments()"
                color="accent">
        Todos
      </mat-chip>
      <mat-chip
                selectable="false"
                [selected]="selectedFilterDocs === 'vinculados'"
                (click)="selectedFilterDocs = 'vinculados';filterDocuments()"
                color="accent">
        Vinculados
      </mat-chip>
      <mat-chip
                selectable="false"
                [selected]="selectedFilterDocs === 'nao-vinculados'"
                (click)="selectedFilterDocs = 'nao-vinculados';filterDocuments()"
                color="accent">
        Não vinculados
      </mat-chip>
    </mat-chip-list>
    <div class="borda" style="height: 380px;    margin-top: 10px;
    ">





      <!-- Render the filtered filteredDocuments based on chip selection -->
      <div class="temas-container list-view"
           *ngIf="filteredDocuments.length > 0 "
           style="word-wrap: break-word;">

        <ul style="list-style-type: none; padding-left: 0;">
          <li *ngFor="let documento of filteredDocuments"
              style="border-bottom: 1px dotted #c9c9c9; padding-bottom: 5px; margin-bottom: 5px;">

            <div class="temas"
                 style="display: flex; align-items: center; justify-content: space-between;">

              <!-- Left section: Toggle group with "O" and "D" options -->
              <div style="display: flex; align-items: center;">


                <!-- Checkbox for enabled documento -->
                <mat-checkbox [(ngModel)]="documento.checked" *ngIf="!documento.disabled"
                              (ngModelChange)="toggleDocumentCheck(documento)"
                              class="checkbox-doc" style="white-space: unset !important;">
                  {{ documento.dostNmDocumento }}
                </mat-checkbox>
                <!-- Obligatorio checkbox (aligned below the document name when checked) -->
                <div *ngIf="documento.checked && !documento.disabled"
                     style="margin-left: 24px; margin-top: 4px; margin-bottom: 24px;">
                  <mat-checkbox class="check-list"
                                (change)="updateObrigatorioVinculo($event, documento)"
                                [checked]="documento.dtstInObrigatorio === 'S'">
                    Obrigatório
                  </mat-checkbox>
                </div>


                <!-- Checkbox for enabled documento -->
                <div *ngIf="documento.disabled" (click)="updateObrigatorioDb(documento)"
                     class="obrigatorio-doc" style="white-space: unset !important;"
                     [ngStyle]="{
                      'background-color': documento.dtstInObrigatorio === 'S' ? '#ad8847' : '#dfdfdf',
                      'color': documento.dtstInObrigatorio === 'S' ? '#ffffff' : '#666666'
                    }">
                  {{ documento.dtstInObrigatorio === 'S' ? 'O' : 'D' }}
                </div>


                <!-- Disabled documento text (aligned with delete button) -->
                <div *ngIf="documento.disabled"
                     style="flex-grow: 1; display: flex; align-items: center; font-size: 13px;">
                  <span
                        [ngStyle]="{ 'font-weight': documento.dtstInObrigatorio === 'S' ? 'bold' : 'normal' }">
                    {{ documento.dostNmDocumento | lowercaseAndTitlecase }}
                  </span>
                </div>
              </div>

              <!-- Right section: Delete button aligned at the right side -->
              <button mat-icon-button color="warn" *ngIf="documento.disabled"
                      (click)="deleteVinculoServDoc(documento)"
                      style="margin-left: 10px;">
                <mat-icon>delete</mat-icon>
              </button>

            </div>

          </li>
        </ul>
      </div>


















      <!-- <div class="borda"
         style="max-width: 920px; padding: 24px; max-height: 500px; overflow-y: auto; overflow-x: hidden;">
      <div *ngFor="let doc of filteredDocuments" class="check-list ">
        <mat-checkbox [(ngModel)]="doc.checked" [disabled]="doc.disabled"
                      style=" white-space: unset !important; font-size: 13px; margin-bottom: 15px;">{{
          doc.dostNmDocumento }} </mat-checkbox>
        <div *ngIf="doc.checked" style="margin-left: 24px; margin-top: 4px; margin-bottom: 24px;">
          <mat-checkbox class="check-list" (change)="updateObrigatorioVinculo($event, doc)"

                        [checked]=" doc.dtstInObrigatorio==='S'">
            Obrigatório
          </mat-checkbox>
        </div>
      </div>
    </div> -->
    </div>
  </div>