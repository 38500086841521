import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin-service';
import { SatDatabaseService } from '@shared/services/sat-database.service';

@Component({
  selector: 'app-crud-servtec',
  templateUrl: './crud-servtec.component.html',
  styleUrls: ['./crud-servtec.component.scss']
})
export class CrudServtecComponent implements OnInit {
  componentToLoad = 'app-crud-view-servtec'
  areasOriginal: any = [];
  areasArr: any = []
  filteredAreas: any = [];
  searchTema = '';
  spinner = true;
  spinnerVinculos = false;
  constructor(private adminService: AdminService, private satDatabaseService: SatDatabaseService) { }

  ngOnInit(): void {
    this.adminService.logadoIsAdmin$.subscribe(isAdmin => {
      console.log('isAdmin', isAdmin)
      this.getAreasTemas()
    });


  }
  exportToPDF(): void {
    const dataToExport = this.areasArr; // Use your data source
    console.log(dataToExport)
    this.adminService.generatePDFWithoutArea(dataToExport);
  }

  getAreasTemas() {
    this.spinner = true;
    this.satDatabaseService.areasLoaded.subscribe((loaded: boolean) => {
      if (loaded === true) {
        this.areasArr = this.satDatabaseService.areasArr;
        this.selectedServico();
      }
    });
  }
  selectedServico() {
    console.log("comecou")
    let areasArrDks = []
    let temasArrDks = []
    let areaTotal = []
    let preAllDocumentosArr = []
    let pushArea
    for (const area of this.areasArr) {
      //  console.log(area)
      area['selected'] = false
      pushArea = false
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      let pushTema
      for (const tema of area.Temas) {
        tema['selected'] = false
        pushTema = false
        let preTema: any = {
          tema: tema[0].tmanNmTema,
          servicos: [],
          checked: false,
        }
        for (const servico of tema[0].servTec) {

          let preServico: any = {
            servico: servico[0].pstcNmServTec,
            DK_PARA_GRAVAR: servico[0].tmsDk,
            tmsDk: servico[0].tmsDk,
            checked: false,
            docsObrigatorios: [],
            docsDesejaveis: []
          }

          servico['docObrigatorios'] = []
          servico['docDesejaveis'] = []


          areasArrDks.push(area.ArstDk)
          temasArrDks.push(tema[0].tmanDk)
          area['selected'] = true
          tema['selected'] = true
          pushArea = true
          pushTema = true

          for (const documento of servico[0].doc) {
            documento.checked = false
            documento.disabled = false
            preAllDocumentosArr.push(documento)
            if (documento.dtstInObrigatorio === "S") {
              preServico.docsObrigatorios.push(documento)
              servico['docObrigatorios'].push(documento);
            }
            if (documento.dtstInObrigatorio === "N") {
              preServico.docsDesejaveis.push(documento)
              servico['docDesejaveis'].push(documento);
            }
          }
          preTema.servicos.push(preServico)



        }
        if (pushTema === true) {
          preArea.temas.push(preTema)
        }

      }

      if (pushArea === true) {
        areaTotal.push(preArea)
      }

    }

    this.getTemas()
    console.log("acabou")
  }
  removeDuplicates(array: any) {
    const uniqueObjects = new Map();

    array.forEach((item: any) => {
      const key = `${item.dostDk}`;
      if (!uniqueObjects.has(key)) {
        uniqueObjects.set(key, item);
      }
    });

    return Array.from(uniqueObjects.values()).sort((a, b) => {
      return a.dostNmDocumento.localeCompare(b.dostNmDocumento);
    });
  }

  getTemas() {
    this.spinner = true;
    let original = this.areasArr;
    console.log(original)
    for (const area of original) {
      let preArea: any = {
        area: area.ArstNmArea,
        temas: []
      }
      for (const tema of area.Temas) {
        let preTema: any = {
          checked: false,
          disabled: false,
          tema: tema[0].tmanNmTema,
          tmanDk: tema[0].tmanDk,
          servicos: []
        }

        for (const servTec of tema[0].servTec) {
          servTec[0].tmanDk = tema[0].tmanDk
          servTec[0].checked = false,
            servTec[0].disabled = false,
            preTema.servicos.push(servTec[0])

        }

        preArea.temas.push(preTema)
      }
      //  this.sortByPstcNmServTec(preArea.temas[0].servicos)
      this.areasOriginal.push(preArea)
    }

  }

  sortByPstcNmServTec(array: any) {
    console.log(array)
    return array.sort((a: any, b: any) => {
      if (a.pstcNmServTec < b.pstcNmServTec) {
        return -1;
      }
      if (a.pstcNmServTec > b.pstcNmServTec) {
        return 1;
      }
      return 0;
    });
  }

}
