<div class="page-container" style="max-width: 920px; padding: 24px;">

  <div *ngIf="viewDetails===true " align="start">
    <button mat-button color="accent" (click)="voltar()">
      <mat-icon>arrow_back</mat-icon>
      Voltar
    </button>
  </div>


  <div
       *ngIf="(viewDetails===false && actionType === null) || (actionType === 'vinculoTemaServ' || actionType === 'vinculoServDoc' )">

    <div class="matero-page-header-inner">
      <h1 class="matero-page-title">ADMIN - Lista de serviços técnicos</h1>

      <div *ngIf="(actionType === 'vinculoTemaServ' || actionType === 'vinculoServDoc')"
           style="display: flex; justify-content: space-between; align-items: center;">
        <!-- First button aligned to the start (left) -->
        <button mat-flat-button color="accent" (click)="voltarVinculo()">
          <mat-icon>arrow_back</mat-icon>
          Voltar
        </button>

        <!-- Second button aligned to the end (right) -->
        <button *ngIf="actionType === 'vinculoTemaServ'" mat-flat-button color="accent"
                style="margin-right: 20px;"
                (click)="saveEditVinculoTemaServ()">Salvar vínculo com Tema</button>
        <button *ngIf="actionType === 'vinculoServDoc'" mat-flat-button color="accent"
                style="margin-right: 20px;"
                (click)="saveEditVinculoServDoc()">Salvar vínculo com Serviço</button>
      </div>

      <p *ngIf="actionType === 'vinculoTemaServ'">
        Selecione a quais <strong> Temas </strong> deseja vincular ao Serviço Técnico:
        {{selectedServTec.pstcNmServTec}}
      </p>
      <p *ngIf="actionType === 'vinculoServDoc'">
        Selecione quais <strong> Documentos </strong> deseja vincular a este serviço:
        {{selectedServTec.pstcNmServTec}}
      </p>
    </div>


    <div style="min-height: 165px; display: block;" *ngIf="actionType !== 'vinculoServDoc'"
         [ngClass]="{'borda': actionType === 'vinculoTemaServ' }">

      <div class="buscar"
           *ngIf="spinner === false && actionType !== 'vinculoTemaServ' && actionType !== 'vinculoServDoc'">
        <mat-form-field appearance="fill" style="width: calc(100% - 7px);">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text"
                 placeholder="Busque pelo serviço"
                 matInput
                 [(ngModel)]="searchGlobal"
                 (keyup)="filterServicosGlobal($event)" />
          <button color="accent"
                  *ngIf="searchGlobal"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="searchGlobal = '' ; filterServicosGlobal($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div *ngIf="spinner === true || panelSpinner === true">
        <div style="margin-bottom: 15px;">Carregando lista de serviços técnicos
        </div>
        <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
      </div>

      <div *ngIf="spinner === false  && panelSpinner === false">
        <!-- <div class="example-action-buttons">
        <button mat-button (click)="openAll()">Expand All</button>
        <button mat-button (click)="accordion.closeAll()">Collapse All</button>
      </div>
 -->
        <div *ngIf="actionType === null ">
          <mat-accordion [multi]="multiAreas" class="mat-elevation-z0 catalogo-expansions">
            <mat-expansion-panel class="expand-area mat-elevation-z0"
                                 *ngFor="let area of filteredAreas" #panel>
              <mat-expansion-panel-header>
                <mat-panel-title class="expand-area-title">
                  <mat-icon *ngIf="area.selected" matPrefix>check</mat-icon>
                  {{ area.area | lowercaseAndTitlecase }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- Only show the nested mat-accordion if this parent panel is expanded -->
              <mat-accordion *ngIf="panel.expanded" class="mat-elevation-z0" multi>
                <mat-expansion-panel multi="true" class="expand-tema mat-elevation-z0"
                                     *ngFor="let tema of area.temas" #panel2>
                  <mat-expansion-panel-header class="temas">
                    <mat-panel-title>
                      <mat-list role="list">
                        <mat-list-item>
                          <mat-icon class="material-icons-outlined"
                                    style="font-size: 20px; color:#5D899D; margin-right: 5px;"
                                    mat-list-icon>folder</mat-icon>
                          <span class="tema">{{ tema.tema | lowercaseAndTitlecase }}</span>
                        </mat-list-item>
                      </mat-list>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div *ngIf="panel2.expanded"
                       style="max-height: 350px; overflow: auto; overflow-x: hidden; margin-bottom: 60px; border-bottom: #c1c1c1 1px solid; padding-bottom: 16px;">
                    <div
                         style="margin: 10px; display: flex; justify-content: flex-end;margin-left: 28px;">
                      <button mat-stroked-button (click)="createServicoTecnico(area, tema)"
                              style="display: flex; align-items: center;">
                        <mat-icon style="margin-right: 8px;">add</mat-icon>
                        Novo Serviço
                      </button>
                    </div>
                    <mat-list role="list" dense>
                      <mat-list-item (click)="viewDetailsSelect(servico, area.area, tema.tema)"
                                     *ngFor="let servico of tema.servicos" class="lista"
                                     style="cursor: pointer;">
                        <span style="min-width: 40px;">
                          <mat-icon class="material-symbols-outlined"
                                    style="font-size: 17px; color:#AD8847; position: absolute; top: 0px;"
                                    mat-list-icon>content_paste</mat-icon>
                        </span>
                        <span class="servico">{{ servico.pstcNmServTec }}</span>
                      </mat-list-item>
                    </mat-list>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-expansion-panel>
          </mat-accordion>

        </div>

        <div *ngIf="actionType === 'vinculoTemaServ'">
          <mat-accordion [multi]="multiAreas" class=" mat-elevation-z0 catalogo-expansions">
            <mat-expansion-panel class="expand-area mat-elevation-z0"
                                 *ngFor="let area of filteredAreas">
              <mat-expansion-panel-header>
                <mat-panel-title class="expand-area-title">
                  <mat-icon *ngIf="area.selected" matPrefix>check</mat-icon>
                  {{ area.area | lowercaseAndTitlecase }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul style="list-style-type: none;">
                <li *ngFor="let tema of area.temas">
                  <div class="temas">
                    <mat-checkbox [(ngModel)]="tema.checked" *ngIf="tema.disabled === false">
                      {{ tema.tema | lowercaseAndTitlecase }}
                    </mat-checkbox>
                    <div *ngIf="tema.disabled === true"
                         style="font-weight: bold; display: flex; justify-content: space-between; align-items: center;">
                      <!-- Text aligned to the left -->
                      <span>{{ tema.tema | lowercaseAndTitlecase }}</span>

                      <!-- Delete button aligned to the right -->
                      <button mat-button color="warn" (click)="deleteVinculoTemaServ(tema.tmanDk)">
                        <mat-icon>delete</mat-icon>
                        Remover vínculo
                      </button>
                    </div>
                  </div>

                  <!--  <div>

                    <ul *ngIf=" actionType==='vinculoServDoc'" style=" list-style-type: none;">
                      <li *ngFor="let servico of tema.servicos" style="cursor: pointer;">
                        <mat-checkbox [(ngModel)]="servico.checked"
                                      style="min-width: 40px; color:#AD8847;">
                          {{ servico.pstcNmServTec }}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </div> -->
                </li>
              </ul>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>



  <!-- LISTA DE DOCUMENTOS COM CHECKBOXES ---------------------------------------------- -->

  <div *ngIf="actionType==='vinculoServDoc'" class="borda"
       style="max-width: 920px; padding: 24px; max-height: 500px; overflow-y: auto; overflow-x: hidden;">
    <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 16px;">
      <mat-label>Buscar</mat-label>
      <input matInput [(ngModel)]="searchDocumentoQuery" (ngModelChange)="filterDocuments()"
             placeholder="Buscar por nome do documento">
    </mat-form-field>

    <div *ngFor="let doc of filteredDocuments" class="check-list">
      <mat-checkbox class="check-list" [(ngModel)]="doc.checked" [disabled]="doc.disabled"
                    style=" white-space: unset !important; font-size: 13px; margin-bottom: 15px;">{{
        doc.dostNmDocumento }} </mat-checkbox>
      <div *ngIf="doc.checked" style="margin-left: 24px; margin-top: 4px; margin-bottom: 24px;">
        <mat-checkbox class="check-list" (change)="updateObrigatorioVinculo($event, doc)"

                      [checked]=" doc.dtstInObrigatorio==='S'">
          Obrigatório
        </mat-checkbox>
      </div>
    </div>
  </div>


  <!--     OPERACOES CRUD -----------------------------------------------------------------    -->

  <div *ngIf=" viewDetails===true || actionType !==null "
       style=" max-width: 920px; padding: 24px;">

    <div *ngIf=" actionType !== 'vinculoTemaServ'&&  actionType !== 'vinculoServDoc'"
         class="matero-page-header-inner" style="    margin-bottom: 24px;
    ">
      <h1 *ngIf="viewDetails===true" class="matero-page-title">ADMIN - Editar serviço técnico
      </h1>
      <h1 *ngIf="actionType === 'insertServTec' " class="matero-page-title">ADMIN - Criar
        serviço
        técnico</h1>
      <h1 *ngIf="actionType === 'updateServTec' " class="matero-page-title">ADMIN - Criar
        documento
      </h1>
      <div style="font-weight: bold;">Área: {{selectedServTec.area}}</div>
      <div>Tema: {{selectedServTec.tema}}</div>
    </div>






    <!-- TELA DE DETALHES -->
    <div *ngIf="viewDetails === true">
      <div style="font-size: 18px; font-weight: 500; margin-bottom: 3px;">Serviço técnico
      </div>
      <div
           style="margin: 10px; display: flex; justify-content: flex-end;margin-left: 28px;">
        <button mat-stroked-button
                (click)="vinculoTemaServ()"
                style="display: flex; align-items: center;">
          <mat-icon style="margin-right: 8px;">linked_services</mat-icon>
          Gerenciar vínculos
        </button>
      </div>
      <div class="borda"
           (click)="editServicoTecnico(selectedServTec)"
           style="margin-bottom: 32px; cursor: pointer; display: flex; align-items: center;
        justify-content: space-between;">

        <div>
          <h2>{{ selectedServTec.pstcNmServTec }}</h2>
          <div>{{ selectedServTec.pstcDsServTec }}</div>
        </div>

        <div style="display: flex; align-items: center;">
          <mat-icon style="font-size: 24px;">edit</mat-icon>
        </div>
      </div>

      <!-- Documentos Obrigatórios -->
      <div
           style="margin: 10px; display: flex; justify-content: flex-end;margin-left: 28px;">
        <button mat-stroked-button
                (click)="vinculoServDoc()"
                style="display: flex; align-items: center; margin-right: 15px;">
          <mat-icon style="margin-right: 8px;">linked_services</mat-icon>
          Vincular documentos
        </button>
        <button mat-stroked-button
                (click)="createDocumento(selectedServTec.area, selectedServTec.tema)"
                style="display: flex; align-items: center;">
          <mat-icon style="margin-right: 8px;">add</mat-icon>
          Novo documento
        </button>
      </div>
      <div class="borda"
           style="margin-top: 24px;">
        <div style="font-size: 15px; font-weight: bold; margin-bottom: 10px;">Documentos
          Obrigatórios
          (clique para editar)
        </div>

        <mat-list role="list">

          <mat-list-item *ngFor="let doc of selectedServTec.docsObrigatorios; let i = index"
                         (click)="editDocumento(selectedServTec, doc)"
                         style="margin-bottom: 12px; cursor: pointer; font-size: 13px;">
            <mat-icon class="material-symbols-outlined"
                      style="font-size: 24px; color:#5D899D;
                           "
                      mat-list-icon>draft</mat-icon>
            <div mat-line> <strong>{{ doc.dostNmDocumento }}</strong> </div>
            <div mat-line> {{ doc.dostDsDocumento }}</div>
          </mat-list-item>
        </mat-list>
      </div>

      <!-- Documentos Desejáveis -->
      <div
           style="margin-top: 24px;" class="borda">
        <div style="font-size: 15px; font-weight: bold; margin-bottom: 10px;">Documentos
          Desejáveis
          (clique para editar)
        </div>

        <mat-list role="list">
          <mat-list-item *ngFor="let doc of selectedServTec.docsDesejaveis let i = index"
                         (click)="editDocumento(selectedServTec, doc)"
                         style="margin-bottom: 12px; cursor: pointer; font-size: 13px;">
            <mat-icon class="material-symbols-outlined"
                      style="font-size: 24px; color:#5D899D;
              "
                      mat-list-icon>draft</mat-icon>
            <div mat-line> <strong>{{ doc.dostNmDocumento }}</strong> </div>
            <div mat-line> {{ doc.dostDsDocumento }}</div>
          </mat-list-item>
        </mat-list>
      </div>

    </div>




    <!--     EDITAR/CRIAR SERVICO TECNICO   -----------------------------------------------------------------    -->

    <div *ngIf="actionType === 'insertServTec' || actionType === 'updateServTec'"
         class="borda">
      <h3 *ngIf="actionType === 'updateServTec' "> Editar Serviço Técnico</h3>
      <h3 *ngIf="actionType === 'insertServTec' ">Criar Serviço Técnico</h3>

      <div style="margin-bottom: 30px; margin-top: 20px;">
        <mat-form-field style="width: 100%;" appearance="fill">
          <mat-label>Nome do Serviço Técnico</mat-label>

          <textarea rows="3" matInput
                    [(ngModel)]="editingServicoTecnico.pstcNmServTec">       </textarea>
        </mat-form-field>
      </div>
      <div style="margin-bottom: 30px;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Descrição do Serviço Técnico</mat-label>
          <textarea *ngIf="selectedServTec !== null" matInput
                    [(ngModel)]="editingServicoTecnico.pstcDsServTec"
                    rows="5"></textarea>
        </mat-form-field>
      </div>

      <div>
        <button *ngIf="actionType === 'updateServTec'"
                [disabled]="editingServicoTecnico.pstcDsServTec === '' ||  editingServicoTecnico.pstcNmServTec === '' || editingServicoTecnico.pstcDsServTec === null ||  editingServicoTecnico.pstcNmServTec === null"
                mat-flat-button color="accent"
                style="margin-right: 20px;"
                (click)="saveEditServicoTecnico('UPDATE')">Salvar</button>
        <button *ngIf="actionType === 'insertServTec'"
                [disabled]="editingServicoTecnico.pstcDsServTec === '' ||  editingServicoTecnico.pstcNmServTec === '' || editingServicoTecnico.pstcDsServTec === null ||  editingServicoTecnico.pstcNmServTec === null"
                mat-flat-button color="accent"
                style="margin-right: 20px;"
                (click)="saveEditServicoTecnico('INSERT')">Criar</button>
        <button color="accent" mat-button
                (click)="cancelEditServicoTecnico()">Cancelar</button>
        <button *ngIf="actionType === 'updateServTec'" color="warn" mat-button
                (click)="saveEditServicoTecnico('DELETE')"
                style="float: right;">Excluir</button>
      </div>
    </div>





    <!--     EDITAR DOCUMENTOS   -----------------------------------------------------------------    -->

    <div *ngIf="(actionType === 'insertDoc' || actionType === 'updateDoc') && editingDoc !== null"
         class="doc-item-edit">
      <h4>
        Serviço Técnico: {{ selectedServTec.pstcNmServTec }}
      </h4>
      <h3 *ngIf="actionType === 'updateDoc' "> Editar documento</h3>
      <h3 *ngIf="actionType === 'insertDoc' ">Criar documento</h3>
      <div style="margin-bottom: 30px;">
        <mat-form-field style="width: 100%;" appearance="fill">
          <mat-label>Nome do documento</mat-label>
          <textarea rows="3" matInput [(ngModel)]="editingDoc.dostNmDocumento"></textarea>
          <button *ngIf="editingDoc.dostNmDocumento" matSuffix mat-icon-button
                  aria-label="Clear"
                  (click)="editingDoc.dostNmDocumento=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div style="margin-bottom: 30px;">
        <mat-form-field style="width: 100%;" appearance="fill">
          <mat-label>Descrição do documento</mat-label>
          <textarea matInput [(ngModel)]="editingDoc.dostDsDocumento" rows="5"></textarea>
          <button *ngIf="editingDoc.dostDsDocumento" matSuffix mat-icon-button
                  aria-label="Clear"
                  (click)="editingDoc.dostDsDocumento=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div style="margin-bottom: 30px;">
        <mat-checkbox (change)="updateObrigatorio($event)"
                      [checked]="editingDoc.dtstInObrigatorio ==='S'">Obrigatório</mat-checkbox>
      </div>

      <div>
        <button *ngIf="actionType === 'updateDoc'"
                [disabled]="editingDoc.dostDsDocumento === '' ||  editingDoc.dostNmDocumento === '' || editingDoc.dostDsDocumento === null ||  editingDoc.dostNmDocumento === null"
                mat-flat-button color="accent"
                style="margin-right: 20px;"
                (click)="saveEditDocumento('UPDATE')">Salvar</button>
        <button *ngIf="actionType === 'insertDoc'"
                [disabled]="editingDoc.dostDsDocumento === '' ||  editingDoc.dostNmDocumento === '' || editingDoc.dostDsDocumento === null ||  editingDoc.dostNmDocumento === null"
                mat-flat-button color="accent"
                style="margin-right: 20px;"
                (click)="saveEditDocumento('INSERT')">Criar documento</button>
        <button color="accent" mat-button (click)="cancelEditDocumento()">Cancelar</button>
        <button *ngIf="actionType === 'updateDoc'" color="warn" mat-button
                (click)="saveEditDocumento('DELETE')"
                style="float: right;">Excluir</button>
      </div>
    </div>
  </div>

</div>