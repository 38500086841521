<!-- DETALHES **************************************************** -->



<div class="sticky">
  <!-- <div class="voltar">
    voltar
  </div> -->
  <div class="title-bar" *ngIf="sat.sateNrSat !== undefined || sat.sateInAprovada === 'N' "
       [style.background-color]="sat.sateInAprovada === 'S' ? '#5D899D' : '#AA4139'">
    <strong>
      <span style="font-size: 18px">
        <span>

          {{sat.sateInAprovada === 'S' ? 'SAT:' : 'Aguardando aprovação'}} <span
                *ngIf="sat.sateInAprovada === 'S'"> {{sat?.sateNrSat}}</span>

          <span *ngIf="sat.itpsDtoList.length > 0 && sat.sateInAprovada === 'S'"
                style="margin-left: 16px; font-size: 15px;"
                (click)="openExternalUrl(sat.itpsDtoList[0].LINK_PROC)" class="text">
            <strong> SEI:</strong>
            <span
                  style="cursor: pointer; text-decoration: underline;">{{sat.itpsDtoList[0].PROCEDIMENTO_SEI
              }}</span>
          </span>
          <span *ngIf="sat.itpsDtoList.length === 0 && sat.sateInAprovada === 'S'"
                style="margin-left: 12px; font-size: 13px;"
                class="text"> (em processo de integração com o SEI)
          </span>
        </span>
      </span>
    </strong>

  </div>


  <div *ngIf="spinner === false && sat.sateInAprovada === 'N'" style=" padding: 3px 6px; background-color: #EFF1F2; border-radius: 3px;     margin-bottom: 15px;
  ">
    Para que esta SAT seja analisada, ela ainda deve ser aprovada e enviada para o GATE pelo
    Membro solicitante.
  </div>

</div>
<div style="min-width: 600px; padding: 24px;">



  <div *ngIf="spinner === true">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    <p>Carregando SAT...</p>
  </div>

  <div style="min-height: 165px; display: block">


    <!-- DADOS DA SAT -->
    <div *ngIf="spinner === false && sat as sat">




      <!-- ROW Detalhes cards SOLICITANTE &  PROCEDIMENTO-->
      <div class="matero-row " style="padding:6px;" fxLayout="row wrap">
        <!-- Detalhes cards -->
        <div style="width: 100%;margin-bottom: 20px;">
          <button *ngIf="sat.sateInAprovada === 'N'" style="margin: 5px;padding-left: 0;" mat-button
                  mat-dialog-close
                  color="accent">
            <mat-icon>arrow_back</mat-icon>
            Voltar
          </button>
          <span style="float: right;">
            <!-- <button *ngIf="sat.sateInAprovada === 'N'" style="margin: 5px;" mat-flat-button
                    (click)="editarSAT(null)"
                    color="accent">Editar SAT</button> -->
            <button *ngIf="sat.sateInAprovada === 'N'" style="margin: 5px;" mat-stroked-button
                    (click)="excluirSATDialog()"
                    color="accent">
              <mat-icon>delete</mat-icon>
              Excluir SAT
            </button>
          </span>
        </div>

        <div *ngIf="sat.sateItproduzida.length === 0" class="confirmacao">
          <div class="title">Gerenciar anexos</div>

          <mat-list style="width: 100%">
            <mat-list-item class="text" style="height: auto; cursor: pointer; "
                           (click)="openDialog()">
              <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                        mat-list-icon>attach_file</mat-icon>
              <div>
                Realizar upload de novos arquivos
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <div *ngIf="sat.sateItproduzida.length > 0" class="confirmacao">
          <div *ngIf="sat.sateItproduzida.length === 1" class="title">Informação Técnica (IT)
            produzida</div>
          <div *ngIf="sat.sateItproduzida.length > 1" class="title">Informações Técnicas (IT)
            produzidas</div>

          <mat-list style="width: 100%">
            <mat-list-item *ngFor="let it of sat.sateItproduzida" class="text"
                           style="height: auto; cursor: pointer;"
                           (click)="abreBase64(it.DOWNLOAD_IT, 'PDF')">
              <mat-icon style="color: rgb(95, 95, 99);padding: 0px; ; margin-right: 6px;"
                        mat-list-icon>picture_as_pdf</mat-icon>
              <div>
                Baixar IT: {{it.NUM_IT}}
              </div>
            </mat-list-item>
          </mat-list>
        </div>

        <!--  DEFINIÇÃO  SOLICITANTE************************************************* -->
        <div class="confirmacao">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('solicitante')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div class="title">
            Membro solicitante
          </div>
          <div class="text">
            {{ sat.usumatriculaSolicit[0].NOME }} -
            Mat. {{ sat.usumatriculaSolicit[0].MATRICULA| slice:-4 }}
          </div>

          <div *ngIf="sat.usumatriculaSolicit[0].MATRICULA === sat.usumatriculaSate[0]?.MATRICULA"
               class="text"
               style="    margin-top: 18px;
               font-size: 12px;"><strong>Enviada em:</strong> {{sat.sateDtCadastro |
            date : 'dd/MM/yyyy' }}
          </div>

          <div *ngIf="sat.usumatriculaSolicit[0].MATRICULA !== sat.usumatriculaSate[0]?.MATRICULA"
               class="text"
               style="    margin-top: 18px;
               font-size: 12px;"><strong>Preenchido por:</strong>
            {{ sat.usumatriculaSate[0]?.NOME }} -
            Mat. {{ sat.usumatriculaSate[0]?.MATRICULA | slice:-4 }}, em {{sat.sateDtCadastro |
            date : 'dd/MM/yyyy' }}
          </div>


          <div
               *ngFor="let file of sat.solAnaliseTecnicaAnexoList; let i = index">
            <div class="single-file"
                 *ngIf="file.saaxTpaxDk === -1">
              <mat-list style="width: 100%">
                <mat-list-item class="text" style="height: auto; cursor: pointer;"
                               (click)="abreBase64(file.url, file.saaxNmExtensaoArquivo)">
                  <mat-icon style="color: rgb(95, 95, 99);padding: 0px; ; margin-right: 6px;"
                            mat-list-icon>picture_as_pdf</mat-icon>
                  <div>
                    Baixar PDF da SAT
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>


          <div *ngIf="sat.solAnaliseTecnicaAnexoList.length > 0">
            <div class="single-file">
              <mat-list style="width: 100%">
                <mat-list-item class="text" style="height: auto; cursor: pointer;"
                               (click)="abreBase64('/prodata/Anexos/downloadAllFile?sateDk='+sat.sateDk, 'zip')">
                  <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                            mat-list-icon>folder_zip</mat-icon>
                  <div>
                    Baixar todos os arquivos da SAT
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>




        </div>




        <!--  DEFINIÇÃO  PROCEDIMENTO  ************************************************* -->


        <div class="confirmacao">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('procedimento')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div class="title">Procedimento</div>
          <div class="text"><strong>Procedimento N.: </strong>{{
            sat.sateNumDocumento }} </div>

          <div class="text"><strong>Órgão de Execução: </strong>{{
            sat.sateOrgiDkList[0].NOME }} </div>

          <div class="text"><strong>Natureza: </strong> {{
            sat.satePssoDk.pssoDk !== 4
            ? 'Extrajudicial'
            : 'Judicial'
            }}
          </div>
          <div *ngIf="sat.procedimentoDAOList !== null" class="text"><strong>Assunto: </strong>{{
            sat.sateAssuntoProcesso }} </div>
          <div class="text"><strong>Origem: </strong>{{
            sat.satePssoDkList.pssoNmSistema }} </div>


          <div class="text">
            <strong>E-mail do Órgão: </strong>{{ sat.sateEmail }}
          </div>
          <div class="text">
            <strong>Município(s) do Objeto: </strong>
            <span *ngIf="sat.cidadeDAOList.length > 0">
              <span *ngFor="let municipio of sat.cidadeDAOList  ; let isLast = last">
                {{municipio.CIDA_NM_CIDADE }}{{isLast ? "" : ", "}}</span>
            </span>
            <span *ngIf="sat.cidadeDAOList.length === 0">
              Estado do Rio de Janeiro
            </span>

          </div>


          <!--  DEFINIÇÃO  COMPLEMENTACAO************************************************* -->


          <div class="subtitle">Complementação</div>
          <div class="text" *ngIf="sat.sateInComplAnalise === 'N'">
            Esta SAT <strong>não </strong> é complementação de IT anterior
          </div>

          <div class="text" *ngIf="sat.sateInComplAnalise === 'S'">
            Esta SAT é complementação da IT:
            <strong>{{sat.sateItcnDkList[0].NUM_IT}}
            </strong>
          </div>
        </div>







        <!--  DEFINIÇÃO  TEMA************************************************* -->
        <div class="confirmacao">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('temas-servicos')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div class="title">Tema(s) e Serviço(s) Técnico(s)</div>


          <div
               *ngFor="let tema of sat.ListTemas[0][0].Temas; let isLast = last"
               [ngClass]="isLast === true ? ' last' : ''">
            <strong>{{ tema.tmanNmTema }}</strong>
            <ul
                *ngIf="tema.servTec[0].length > 0">
              <span *ngIf="tema.servTec[0].length > 0">
                <li *ngFor="let servico of tema.servTec[0]">
                  {{ servico.pstcNmServTec }}


                  <div *ngIf="false">

                    <div class="docs-title">
                      Documentos obrigatórios
                    </div>
                    <ul>
                      <div *ngFor="let documento of servico.doc">
                        <li *ngIf="documento.dtstInObrigatorio === 'S'" style="font-size: 14px;">

                          {{documento.dostNmDocumento}}


                          <!-- <mat-icon
                                  style="font-size: 16px; color: #666"
                                  *ngIf="documento.fileName !== null"
                                  [matTooltip]="documento.fileName"
                                  mat-list-icon>insert_drive_file</mat-icon> -->
                        </li>
                      </div>
                    </ul>
                    <div class="docs-title">
                      Documentos adicionais
                    </div>
                    <ul>
                      <div *ngFor="let documento of servico.doc">
                        <li *ngIf="documento.dtstInObrigatorio === 'N'" style="font-size: 14px;">

                          {{documento.dostNmDocumento}}


                          <!-- <mat-icon
                              style="font-size: 16px; color: #666"
                              *ngIf="documento.fileName !== null"
                              [matTooltip]="documento.fileName"
                              mat-list-icon>insert_drive_file</mat-icon> -->
                        </li>
                      </div>
                    </ul>
                  </div>


                </li>
              </span>
            </ul>
          </div>

        </div>


        <!--  DEFINIÇÃO  DOCUMENTOS ************************************************* -->
        <div class="confirmacao"
             *ngIf="sat.satePssoDk === 1 || sat.satePssoDk === 2     ">
          <!--  <div class="confirmacao"> -->
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('documentos-upload')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div>
            <div class="title">Upload de documentos</div>
            <div class="files-list">
              <div class="subtitle">Obrigatórios</div>
              <div
                   *ngFor="let file of sat.solAnaliseTecnicaAnexoList; let i = index">
                <div class="single-file"
                     *ngIf="file.saaxTpaxDk === 3 && file.saaxInObrigatorio === 'S'">
                  <mat-list style="width: 100%">
                    <mat-list-item class="text" style="height: auto; cursor: pointer;"
                                   (click)="abreBase64(file.url, file.saaxNmExtensaoArquivo)">
                      <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                                mat-list-icon>attach_file</mat-icon>
                      <div>
                        {{ file.saaxNmArquivo }}
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
            </div>
            <div class="files-list">
              <div class="subtitle">Adicionais</div>
              <div *ngFor="let file of sat.solAnaliseTecnicaAnexoList; let i = index">
                <div class="single-file"
                     *ngIf="file.saaxTpaxDk === 3 && file.saaxInObrigatorio ==='N' ">
                  <mat-list style="width: 100%">
                    <mat-list-item class="text" style="height: auto; cursor: pointer;"
                                   (click)="abreBase64(file.url, file.saaxNmExtensaoArquivo)">
                      <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                                mat-list-icon>attach_file</mat-icon>
                      <div>
                        {{ file.saaxNmArquivo }}
                      </div>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
            </div>
          </div>



        </div>

        <div class="confirmacao"
             *ngIf="sat.satePssoDk !== 1 && sat.satePssoDk !== 2     ">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('documentos-integra')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>

          <div class="title">Documentos alocados no Integra (* obrigatórios)</div>
          <div class="files-list">

            <div *ngFor="let idIntegra of sat.docTemaServTecSatList; let i = index">
              <div class="single-file">
                <mat-list style="width: 100%">
                  <mat-list-item class="text" style="height: auto; ">

                    <div>
                      <strong>{{ idIntegra.dssaIdDocIntegra }}</strong>
                      <span *ngFor="let tema of sat.ListTemas[0][0].Temas">

                        <span *ngFor="let servico of tema.servTec[0]">



                          <span *ngFor="let documento of servico.doc">

                            <span *ngIf="documento.dtstDk === idIntegra.dssaDtstDk">
                              - {{documento.dostNmDocumento}} <span
                                    *ngIf="documento.dtstInObrigatorio === 'S'">*</span>
                            </span>



                          </span>
                        </span></span>
                    </div>


                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>

        </div>




        <!--  DEFINIÇÃO  EXCEPCIONALIDADE************************************************* -->
        <div class="confirmacao">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('complementaridade')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div class="title">Complementaridade</div>
          <div *ngIf="sat.sateInManifTecAnterior=== 'S'">
            Esta SAT <strong>não </strong> possui excepcionalidade
            <div>Laudo a ser complementado: </div>
            <div *ngFor="let file of sat.solAnaliseTecnicaAnexoList; let i = index">
              <div class="single-file"
                   *ngIf="file.saaxTpaxDk === 2">
                <mat-list style="width: 100%">
                  <mat-list-item class="text" style="height: auto; cursor: pointer;"
                                 (click)="abreBase64(file.url, file.saaxNmExtensaoArquivo)">
                    <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                              mat-list-icon>attach_file</mat-icon>
                    <div>
                      {{ file.saaxNmArquivo }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
          <div *ngIf="sat.sateInManifTecAnterior === 'N'">
            <strong> Esta SAT possui excepcionalidade: </strong>
            {{ sat.sateDsJustifAusenciaCompl }}

            <div *ngFor="let file of sat.solAnaliseTecnicaAnexoList; let i = index">
              <div class="single-file"
                   *ngIf="file.saaxTpaxDk === 5 ">
                <mat-list style="width: 100%">
                  <mat-list-item class="text" style="height: auto; cursor: pointer;"
                                 (click)="abreBase64(file.url, file.saaxNmExtensaoArquivo)">
                    <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                              mat-list-icon>attach_file</mat-icon>
                    <div>
                      {{ file.saaxNmArquivo }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </div>




        <!--  DEFINIÇÃO  PREFERENCIA LEGAL************************************************* -->
        <div class="confirmacao">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('prioridade')"
                  class="editar" aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div class="title">Prioridade</div>

          <div class="text" *ngIf="sat.satePlegDk === 1">
            Esta SAT <strong>não </strong> possui preferência legal
          </div>
          <div class="text" style="margin-bottom: 15px;"
               *ngIf="sat.satePlegDk !== 1">
            <strong>Preferência legal: </strong>
            {{sat.satePlegDkList[0].plegNmPreferenciaLegal
            }}
          </div>


          <!--  DEFINIÇÃO  PRIORIDADE************************************************* -->

          <div class="text" *ngIf="sat.sateInPrioridade === 'N'">
            Esta SAT <strong>não </strong> necessita de atendimento prioritário
          </div>
          <div class="text" *ngIf="sat.sateInPrioridade === 'S'">
            <strong>Atendimento prioritário: </strong>
            {{ sat.tpPrioridadeAnalise[0].tpplNmTpPrioridade }}
            {{
            sat.sateTpplDk && sat.sateTpplDk === 4 ? ' - ' +
            sat.sateDsJustPrioridade
            : null }}


            <div>Documento(s) anexado(s) </div>
            <div *ngFor="let file of sat.solAnaliseTecnicaAnexoList; let i = index">
              <div class="single-file"
                   *ngIf="file.saaxTpaxDk === 1">
                <mat-list style="width: 100%">
                  <mat-list-item class="text" style="height: auto; cursor: pointer;"
                                 (click)="abreBase64(file.url, file.saaxNmExtensaoArquivo)">
                    <mat-icon style="color: rgb(95, 95, 99);padding: 0px; margin-right: 6px;"
                              mat-list-icon>attach_file</mat-icon>
                    <div>
                      {{ file.saaxNmArquivo }}
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>

            <div class="text" style="margin-top: 12px;">
              <strong> Data limite para atendimento: </strong>
              {{ sat.sateDtLimite | date : 'dd/MM/yyyy' }}
            </div>
          </div>


        </div>


        <!--  DEFINIÇÃO  DUVIDA TECNICA************************************************* -->
        <div class="confirmacao">
          <button *ngIf="sat.sateInAprovada === 'N'" (click)="editarSAT('duvida')" class="editar"
                  aria-label="Editar"
                  style="display: flex; align-items: center; border: none; background: none; cursor: pointer;">
            <mat-icon style="font-size: 21px; width: 25px; height: 21px;">
              edit_document
            </mat-icon>
            Editar
          </button>
          <div class="title">Objetivo da solicitação</div>
          <div class="text">{{ sat.sateDsDuvida }}</div>
        </div>




      </div>



    </div>









  </div>
  <div *ngIf="sat.sateInAprovada === 'N' && logadoIsSolicitante === true"
       style="margin-top: 30px;">
    <button mat-button (click)="submeterSat()" mat-flat-button
            color="accent">Aprovar e enviar para o GATE</button>
  </div>
</div>